import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { NextPage } from 'next'
import { ReactElement, ReactNode, useEffect } from 'react'
import { SessionProvider } from 'next-auth/react'
import Script from 'next/script'
import HeapConfig from '../config/heapConfig'
import { useRouter } from 'next/router'

export type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout
}

function MyApp({
	Component,
	pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
	const getLayout = Component.getLayout ?? ((page) => page)
	const router = useRouter()

	useEffect(() => {
		const handleRouteChange = () => {
			window.digitalData = {
				page: {
					page_uri:
						window.location.hostname + window.location.pathname,
					brand_media: 'vrt',
					brand_technology: 'openmedia platform',
					channel: 'web',
					page_domain: window.location.host,
					page_title: document.title,
					page_language: 'nl',
					environment: process.env.NODE_ENV,
					page_technology: 'single page',
					trackPageView: 'true',
				},
			}
		}

		router.events.on('routeChangeComplete', handleRouteChange)
		handleRouteChange()

		return () => {
			router.events.off('routeChangeStart', handleRouteChange)
		}
	}, [])

	return (
		<SessionProvider
			session={session}
			refetchInterval={5 * 60}
			refetchOnWindowFocus={true}
		>
			<Script
				id={'adobeAnalytics'}
				async={true}
				src={
					'https://assets.adobedtm.com/launch-EN7e1ba6190f45421f81025c684b630cc1.min.js'
				}
			/>
			<Script
				id={'heapAnalytics'}
				strategy={'afterInteractive'}
				dangerouslySetInnerHTML={{
					__html: ` window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("${HeapConfig.id}");`,
				}}
			/>
			{getLayout(<Component {...pageProps} />)}
		</SessionProvider>
	)
}

export default MyApp
